import React, { useState } from 'react';
import { Button, CircularProgress, Typography, Box } from '@mui/material';
import { UploadCloud } from 'lucide-react';

const API_URL = '/api';

const CSVUpload = ({ accessToken }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadResult, setUploadResult] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    setIsUploading(true);
    setUploadResult(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${API_URL}/upload_csv/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData
      });

      const result = await response.json();

      if (response.ok) {
        setUploadResult({
          success: true,
          message: `Successfully ingested ${result.count} entries.`
        });
      } else {
        setUploadResult({
          success: false,
          message: result.detail || 'Failed to ingest CSV data.'
        });
      }
    } catch (error) {
      console.error('Error uploading CSV:', error);
      setUploadResult({
        success: false,
        message: 'An error occurred while uploading the CSV.'
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Upload CGM Readings CSV
      </Typography>
      <input
        accept=".csv"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="outlined" component="span" startIcon={<UploadCloud />}>
          Select CSV File
        </Button>
      </label>
      {file && <Typography variant="body2" sx={{ mt: 1 }}>{file.name}</Typography>}
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={!file || isUploading}
        sx={{ mt: 2, ml: 2 }}
      >
        Upload and Ingest
      </Button>
      {isUploading && <CircularProgress sx={{ ml: 2 }} />}
      {uploadResult && (
        <Typography
          variant="body1"
          sx={{ mt: 2, color: uploadResult.success ? 'green' : 'red' }}
        >
          {uploadResult.message}
        </Typography>
      )}
    </Box>
  );
};

export default CSVUpload;