import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Alert, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Calendar } from 'lucide-react';
import CSVUpload from './CSVUpload';
import moment from 'moment-timezone';


const API_URL = '/api';

const GlucAdmin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [foodItems, setFoodItems] = useState([]);
  const [activityItems, setActivityItems] = useState([]);
  const [newFood, setNewFood] = useState('');
  const [newActivity, setNewActivity] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('');
  const [duration, setDuration] = useState('');
  const [intensity, setIntensity] = useState('');
  const [entryTime, setEntryTime] = useState(moment().format('YYYY-MM-DDTHH:mm'));
  const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [combinedData, setCombinedData] = useState([]);

  const [insightType, setInsightType] = useState('');
  const [insightDescription, setInsightDescription] = useState('');
  const [insightSeverity, setInsightSeverity] = useState('');

  useEffect(() => {
    if (isLoggedIn) {
      fetchCombinedData();
    }
  }, [isLoggedIn]);

  const fetchCombinedData = async () => {
    try {
      const response = await fetch(`${API_URL}/user/${username}/combined_data/?hours=24`);
      if (response.ok) {
        const data = await response.json();
        setCombinedData(data);
      } else {
        console.error('Failed to fetch combined data');
      }
    } catch (error) {
      console.error('Error fetching combined data:', error);
    }
  };

  const fetchFoodItems = async () => {
    try {
      const response = await fetch(`${API_URL}/foods`);
      if (!response.ok) throw new Error('Failed to fetch food items');
      const data = await response.json();
      setFoodItems(data);
    } catch (err) {
      setError('Failed to fetch food items');
    }
  };

  const fetchActivityItems = async () => {
    try {
      const response = await fetch(`${API_URL}/activities`);
      if (!response.ok) throw new Error('Failed to fetch activity items');
      const data = await response.json();
      setActivityItems(data);
    } catch (err) {
      setError('Failed to fetch activity items');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });
      const data = await response.json();

      if (response.ok) {
        setIsLoggedIn(true);
        setAccessToken(data.access_token);
      } else {
        setError(data.detail || 'Login failed. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await fetch(`${API_URL}/logout`, {
        method: 'POST',
        credentials: 'include',
      });
    } catch (err) {
      console.error('Logout failed:', err);
    }
    setIsLoggedIn(false);
    setUsername('');
    setPassword('');
  };

  const handleFoodSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const foodLogData = {
      name: newFood,
      quantity: 1, // Default quantity
      unit: 'serving', // Default unit
      timestamp: moment.tz(entryTime, selectedTimezone).utc().format()
    };

    try {
      const response = await fetch(`${API_URL}/food_logs/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(foodLogData),
      });
      const responseData = await response.json();

      if (response.ok) {
        console.log('Food log created:', responseData);
        setNewFood('');
        setEntryTime(moment().format('YYYY-MM-DDTHH:mm'));
      } else {
        setError(responseData.detail || 'Failed to create food log. Please try again.');
      }
    } catch (err) {
      console.error('Error creating food log:', err);
      setError('An error occurred. Please try again.');
    }
  };

  const handleInsightSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const insightData = {
      type: insightType,
      description: insightDescription,
      severity: insightSeverity,
    };

    try {
      const response = await fetch(`${API_URL}/insights/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(insightData),
      });
      const responseData = await response.json();

      if (response.ok) {
        console.log('Insight created:', responseData);
        setInsightType('');
        setInsightDescription('');
        setInsightSeverity('');
      } else {
        setError(responseData.detail || 'Failed to create insight. Please try again.');
      }
    } catch (err) {
      console.error('Error creating insight:', err);
      setError('An error occurred. Please try again.');
    }
  };

  const handleActivitySubmit = async (e) => {
    e.preventDefault();
    setError('');
    const activityLogData = {
      name: newActivity,
      duration: 30, // Default duration in minutes
      intensity: intensity || 'medium', // Default to medium if not specified
      timestamp: moment.tz(entryTime, selectedTimezone).utc().format()
    };

    try {
      const response = await fetch(`${API_URL}/activity_logs/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(activityLogData),
      });
      const responseData = await response.json();

      if (response.ok) {
        console.log('Activity log created:', responseData);
        setNewActivity('');
        setIntensity('');
        setEntryTime(moment().format('YYYY-MM-DDTHH:mm'));
      } else {
        setError(responseData.detail || 'Failed to create activity log. Please try again.');
      }
    } catch (err) {
      console.error('Error creating activity log:', err);
      setError('An error occurred. Please try again.');
    }
  };


  if (!isLoggedIn) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f0f0f0' }}>
        <div style={{ padding: '2rem', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <h1 style={{ marginBottom: '1rem', fontSize: '1.5rem', fontWeight: 'bold' }}>GlucAdmin Login</h1>
          <form onSubmit={handleLogin}>
            <TextField
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
              Login
            </Button>
          </form>
          {error && <Alert severity="error" style={{ marginTop: '1rem' }}>{error}</Alert>}
        </div>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>GlucAdmin</h1>
        <Button onClick={handleLogout} variant="outlined">Logout</Button>
      </div>
      <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
        <TabList>
          <Tab>Food</Tab>
          <Tab>Activity</Tab>
          <Tab>CGM Upload</Tab>
          <Tab>Insights</Tab>
        </TabList>
        <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
          <TabPanel>
          <form onSubmit={handleFoodSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <TextField
              label="Food"
              value={newFood}
              onChange={(e) => setNewFood(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Time"
              type="datetime-local"
              value={entryTime}
              onChange={(e) => setEntryTime(e.target.value)}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select
                value={selectedTimezone}
                onChange={(e) => setSelectedTimezone(e.target.value)}
                label="Timezone"
              >
                {moment.tz.names().map((tz) => (
                  <MenuItem key={tz} value={tz}>{tz}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary">
              Add Food Entry
            </Button>
          </form>
        </TabPanel>
          <TabPanel>
          <form onSubmit={handleActivitySubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <TextField
              label="Activity"
              value={newActivity}
              onChange={(e) => setNewActivity(e.target.value)}
              required
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel>Intensity</InputLabel>
              <Select
                value={intensity}
                onChange={(e) => setIntensity(e.target.value)}
                label="Intensity"
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Time"
              type="datetime-local"
              value={entryTime}
              onChange={(e) => setEntryTime(e.target.value)}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select
                value={selectedTimezone}
                onChange={(e) => setSelectedTimezone(e.target.value)}
                label="Timezone"
              >
                {moment.tz.names().map((tz) => (
                  <MenuItem key={tz} value={tz}>{tz}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary">
              Add Activity Entry
            </Button>
          </form>
        </TabPanel>
        <TabPanel>
          <CSVUpload accessToken={accessToken} />
        </TabPanel>
          <TabPanel>
          <form onSubmit={handleInsightSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <FormControl fullWidth>
              <InputLabel>Insight Type</InputLabel>
              <Select
                value={insightType}
                onChange={(e) => setInsightType(e.target.value)}
                label="Insight Type"
                required
              >
                <MenuItem value="anomaly">Anomaly</MenuItem>
                <MenuItem value="food_impact">Food Impact</MenuItem>
                <MenuItem value="activity_impact">Activity Impact</MenuItem>
                <MenuItem value="pattern">Pattern</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Description"
              value={insightDescription}
              onChange={(e) => setInsightDescription(e.target.value)}
              required
              fullWidth
              multiline
              rows={4}
            />
            <FormControl fullWidth>
              <InputLabel>Severity</InputLabel>
              <Select
                value={insightSeverity}
                onChange={(e) => setInsightSeverity(e.target.value)}
                label="Severity"
                required
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary">
              Add Insight
            </Button>
          </form>
        </TabPanel>
        </Tabs>
      </Tabs>
      {error && <Alert severity="error" style={{ marginTop: '1rem' }}>{error}</Alert>}
      {/* <div>*/}
      {/*  <h2>Combined Data (Last 24 hours)</h2>*/}
      {/*  <ul>*/}
      {/*    {combinedData.map((item, index) => (*/}
      {/*      <li key={index}>*/}
      {/*        {new Date(item.timestamp).toLocaleString()}:*/}
      {/*        {item.glucose !== null && ` Glucose: ${item.glucose}`}*/}
      {/*        {item.food !== null && ` Food: ${item.food}`}*/}
      {/*        {item.activity !== null && ` Activity: ${item.activity}`}*/}
      {/*      </li>*/}
      {/*    ))}*/}
      {/*  </ul>*/}
      {/*</div>*/}
      {error && <Alert severity="error" style={{ marginTop: '1rem' }}>{error}</Alert>}
    </div>
  );
};

export default GlucAdmin;